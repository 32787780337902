/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components), {Youtube} = _components;
  if (!Youtube) _missingMdxReference("Youtube", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "PlagiaShield is now in public Beta. You can check up to 500 pages for one domain, once."), "\n", React.createElement(Youtube, {
    url: "https://www.youtube.com/watch?v=yw0xQx0l_UI"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
